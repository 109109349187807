<template>
  <div
    ref="chatCarousel"
    v-if="isOpened"
    @keyup.esc.stop="close"
    class="chat-carousel"
    tabindex="0">
    <div class="chat-carousel__wrapper">
      <div class="chat-carousel__content">
        <div
          @click="close"
          class="chat-carousel__content-icon">
          <close-icon/>
        </div>
        <img
          v-if="showImage"
          :src="sourceUrl"
          class="chat-carousel__content-image"/>
        <video-player
          v-if="showVideo"
          :options="videoOptions"/>
      </div>
    </div>
  </div>
</template>

<script>
import {
  CAROUSEL_HIDE,
  CAROUSEL_OPEN,
} from '@/eventTypes';
import EventBus from '@/eventBus';
import VideoPlayer from '@/components/shared/VideoPlayer.vue';

export default {
  components: {
    VideoPlayer,
  },
  data() {
    return {
      isOpened: false,
      currentUrl: '',
      currentType: '',
    };
  },
  computed: {
    sourceUrl() {
      return this.currentUrl;
    },
    showImage() {
      return this.currentType === 'image' && this.currentUrl.length > 0;
    },
    showVideo() {
      return this.currentType === 'video' && this.currentUrl.length > 0;
    },
    videoOptions() {
      return {
        autoplay: true,
        controls: true,
        loop: false,
        sources: [{
          src: this.sourceUrl,
          type: 'video/mp4',
        }],
        controlBar: {
          pictureInPictureToggle: false,
        },
      };
    },
  },
  mounted() {
    EventBus.$on(CAROUSEL_OPEN, (payload) => {
      this.isOpened = true;
      this.currentUrl = payload.url;
      this.currentType = payload.type;
    });

    EventBus.$on(CAROUSEL_HIDE, () => {
      this.close();
      this.currentUrl = '';
      this.currentType = '';
    });
  },
  watch: {
    isOpened(newVal) {
      if (newVal) {
        this.$nextTick(() => this.open());
      }
    },
  },
  methods: {
    open() {
      this.$refs.chatCarousel.focus();
    },
    close() {
      this.isOpened = false;
    },
  },
};
</script>

<style scoped lang="scss">
  @import '~styles/components/chat/_index.scss';
</style>
